import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'

import PageTemplate from './page_template'
import DataTable from './datatable'
import api from '../api'


const PaymentsReport = (props) => {
    const [rows, setRows] = useState([])
    useEffect(() => {
        api.payments_report()
            .then( data => setRows(data))
            .catch( error => !api.cancelled && setRows([]))
    }, [])
    const config = [
        { align: 'left', label: 'Дата', heading: true, value: row => moment(row.date).format('DD.MM.YYYY HH:mm') },
        { align: 'right', label: 'Сума', heading2: true, value: row => row.amount, format: 'amount' },
        { align: 'left', label: 'Начин на плащане', value: row => row.payment_means === 'EazyPay' ? 'EasyPay' : row.payment_means },
    ]
    return (
    <PageTemplate
        title='Моите плащания'
        description='Списък с извършените от Вас плащания'>
      <DataTable config={config} rows={rows} />
    </PageTemplate>
    )
}


export default PaymentsReport


