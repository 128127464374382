import React from 'react'
//import { useState } from 'react'
import clsx from 'clsx'
import { Hidden } from '@material-ui/core'
/*
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import RestoreIcon from '@material-ui/icons/Restore'
import FavoriteIcon from '@material-ui/icons/Favorite'
import LocationOnIcon from '@material-ui/icons/LocationOn'
*/


const Footer = (props) => {
    const { footerShadow, footerBgTransparent } = props
    //const [value, setValue] = useState(0)
    return (
    <>
      <Hidden mdDown>
        <div
          className={clsx('app-footer text-black-50', {
            'app-footer--shadow': footerShadow,
            'app-footer--opacity-bg': footerBgTransparent
          })}>
          <div className='app-footer--first'>
          </div>
          <div className='app-footer--second'>
            <span>Акорд Онлайн - Всички права запазени</span> © {new Date().getFullYear()}
          </div>
        </div>
      </Hidden>
      {/*
      <Hidden mdUp>
        <BottomNavigation value={value} onChange={(event, value) => { setValue(value) }}
          showLabels
          >
          <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
          <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
          <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
        </BottomNavigation>
      </Hidden>
      */}
    </>
    )
}

export default Footer


