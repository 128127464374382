import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'

import PageTemplate from './page_template'
import DataTable from './datatable'
import api from '../api'


const CommunalReport = (props) => {
    const [rows, setRows] = useState([])
    useEffect(() => {
        api.communal_report()
            .then( data => setRows(data))
            .catch( error => !api.cancelled && setRows([]))
    }, [])
    var config = []
    if (rows.length) {
        config = [
            { align: 'left', label: 'Месец', heading: true, value: row => moment(row.month).format('MMM YYYY') },
            { align: 'right', label: 'Брой членове', value: row => row.people_count },
            ...Object.keys(rows[0].allocations).map( (a, idx) => {
                return { align: 'right', label: a, value: row => Object.values(row.allocations)[idx], format: 'amount' }
            }),
            { align: 'right', label: 'Старо', value: row => row.old_amount, format: 'amount' },
            { align: 'right', label: 'Ново', value: row => row.new_amount, format: 'amount' },
            { align: 'right', label: 'Общо', heading2: true, value: row => row.total_amount, format: 'amount' },
            { align: 'right', label: 'Платено', value: row => row.paid_amount, format: 'amount' },
        ]
    }
    return (
    <PageTemplate
        title='Моите разходи'
        description='Начислените разходи за домакинството по месеци'>
      <DataTable config={config} rows={rows} />
    </PageTemplate>
    )
}

export default CommunalReport


