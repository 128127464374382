import React from 'react'
import clsx from 'clsx'

import HomeWorkTwoToneIcon from '@material-ui/icons/HomeWorkTwoTone'
import { inject } from 'mobx-react'

const PageTitle = inject('ui_store')( (props) => {
    const {
        ui_store,
        titleHeading,
        titleDescription,
        children
    } = props
    const page_title = ui_store.page_title
    return (
    <>
      <div
        className={clsx('app-page-title', page_title.style, page_title.background, {
          'app-page-title--shadow': page_title.shadow
        })}>
        <div>
          <div className='app-page-title--first'>
            {page_title.iconbox && (
              <div className='app-page-title--iconbox d-70'>
                <div className='d-70 d-flex align-items-center justify-content-center display-1'>
                  <HomeWorkTwoToneIcon className='text-primary' />
                </div>
              </div>
            )}
            <div className='app-page-title--heading'>
              <h1>{titleHeading}</h1>
              {page_title.description && (
                <div className='app-page-title--description'>
                  {titleDescription}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center'>
          {children}
        </div>
      </div>
    </>
    )
})

export default PageTitle


