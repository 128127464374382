import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Container, Button, Box, Hidden, Grid } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import hero from '../assets/images/hero.jpg'
import config from '../config'


const HeroPage = (props) => {
    const location = useLocation()
    const is_offices = location.pathname.includes( config.urls.offices_anon)
    const {
        title,
        subtitle,
        button_text,
        error,
        onSubmit,
        link_to, link_text,
    } = props
    return (
    <Box>
      <Grid container className="min-vh-100">
        <Grid item md={12} lg={6}>
          <Box height="100%" display="flex" alignItems="center">
          <Container maxWidth="sm">
            <Box mt={0} py={0}>
              <Box my={5} textAlign='center'>
                <h1 className='display-4 mb-1 font-weight-bold'>
                  {title}
                </h1>
                <p className='font-size-lg mb-0 text-black-50'>
                  {subtitle}
                </p>
              </Box>

              <form onSubmit={onSubmit}>
                { error && ( <Alert severity='error'> {error} </Alert>) }
                { props.children }
                { link_to && link_text && (<Link to={link_to}>{link_text}</Link>) }
                { onSubmit && button_text && (
                <Box textAlign="center" mb={5}>
                  <Button size="large" fullWidth type="submit"
                    className='text-uppercase font-weight-bold font-size-sm mt-4 btn-primary'>
                    {button_text}
                  </Button>
                </Box>
                )}

              </form>
            </Box>
          </Container>
          </Box>
        </Grid>

        <Hidden mdDown>
          <Grid item md={6} className="d-flex">
            <div className="hero-wrapper w-100 bg-composed-wrapper bg-premium-dark min-vh-lg-100">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--image opacity-5" style={{ backgroundImage: 'url(' + hero + ')' }} />
                <div className="bg-composed-wrapper--bg bg-second opacity-6" />
                <div className="bg-composed-wrapper--bg bg-deep-blue opacity-2" />
                <div className="bg-composed-wrapper--content text-center p-5">
                  <div className="text-white px-0 px-lg-2 px-xl-4">
                    <h1 className="display-3 mb-4 font-weight-bold"> АКОРД ОНЛАЙН </h1>
                    <h2 className="display-3 mb-4"> Домоуправителят на гр.Варна </h2>
                    <p className="font-size-lg mb-0 opacity-8">
                      Ние ценим вашето време и се грижим за вашето спокойствие всеки ден.
                    </p>
                    <div className="divider mx-auto border-1 my-5 border-light opacity-2 rounded w-25" />
                    <Box textAlign="center" mb={5}>
                    { !is_offices ? (
                      <Button
                        size="large"
                        component={Link} to={config.urls.offices_anon}
                        variant="contained" className='text-uppercase font-weight-bold font-size-sm mt-4'>
                        Контакти
                      </Button>
                      ) : (
                      <Button
                        size="large"
                        component={Link} to={config.urls.root}
                        variant="contained" color="primary" className='text-uppercase font-weight-bold font-size-sm mt-4'>
                        Към Акорд Онлайн
                      </Button>
                    )}
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Hidden>
      </Grid>
    </Box>
    )
}

export default HeroPage



