import React, { useEffect, useState, } from 'react'

import PageTemplate from './page_template'
import DataTable from './datatable'
import api from '../api'


const CommunalReportAll = (props) => {
    const [rows, setRows] = useState([])
    useEffect(() => {
        api.communal_report_all()
            .then( data => setRows(data))
            .catch( error => !api.cancelled && setRows([]))
    }, [])
    var config = []
    if (rows.length) {
        config = [
            { align: 'left', label: '№', heading: true, value: row => row.id_code },
            { align: 'right', label: 'Етаж', value: row => row.floor},
            { align: 'left', label: 'Семейство', value: row => row.owner_name},
            { align: 'left', label: 'КИН', value: row => row.owner_kin},
            { align: 'right', label: 'Брой членове', value: row => row.people_count},
            ...rows[0].expected_amounts.map( (o, idx) => ({
                align: 'right',
                label: o.label,
                value: row => row.expected_amounts[idx].amount, format: 'amount'
            })),
            { align: 'right', label: 'Старо', value: row => row.amount_old, format: 'amount'},
            { align: 'right', label: 'Ново', value: row => row.amount_new, format: 'amount'},
            { align: 'right', label: 'Общо', heading2: true, value: row => row.amount_total, format: 'amount'},
        ]
    }
    return (
    <PageTemplate
        title='Приходи и разходи на етажните собствености'
        description='Текущо начислени приходи и разходи за всички домакинства в сградата'
      >
      <DataTable config={config} rows={rows} />
    </PageTemplate>
    )
}


export default CommunalReportAll


