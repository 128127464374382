import React, { useState } from 'react'
import { Hidden, Box, List, ListItem, Button, TextField } from '@material-ui/core'
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone'
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone'
import CheckIcon from '@material-ui/icons/Check'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { useDropzone } from 'react-dropzone'

import PageTemplate from './page_template'
import api from '../api'


const maxFiles = 3

const ReportIssue = (props) => {
    const {
        acceptedFiles,
        isDragActive,
        isDragAccept,
        isDragReject,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: 'image/jpeg, image/png',
        maxFiles: maxFiles,
    })
    const files = acceptedFiles.map((file) => (
        <ListItem
            className="font-size-sm px-3 py-2 text-primary d-flex justify-content-between align-items-center"
            key={file.path}>
            <span>{file.path}</span>{' '}
            <span className="badge badge-pill bg-neutral-warning text-warning">
                {file.size} bytes
            </span>
        </ListItem>
    ))

    const [description, setDescription] = useState('')
    const [done, setDone] = useState(false)
    const [error, setError] = useState('')
    const handleSubmit = (e) => {
        e.preventDefault()
        api.report_issue( description, acceptedFiles)
            .then( (data) => {
                setDone(true)
                setError('')
                setDescription('')
                acceptedFiles.splice(0, acceptedFiles.length)
                return data
            })
            .catch( (data) => setError( data.description || data.toString()))
    }
    return (
    <PageTemplate
        title='Сигнализирай за проблем'
        description='Посочете ни забелязан проблем и ние ще го разрешим в най-кратък срок'>
      <Box p={3}>
        { error ? ( <Alert severity='error'> {error} </Alert>) : '' }
        { !done ? (
        <>
          <Box textAlign="center" mb={5}>
            <TextField
              fullWidth
              variant='outlined'
              label="Кратко описание"
              multiline
              rowsMax="4"
              id='textfield-email'
              name='description' onChange={ (e) => setDescription(e.target.value) }
            />
          </Box>

          <Box textAlign="center" className="dropzone">
            <div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
              <input {...getInputProps()} />
              <div className="dropzone-inner-wrapper">
                <Hidden smDown>
                {isDragAccept && (
                  <>
                    <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                      <CheckIcon className="d-50" />
                    </div>
                    <Box fontSize="fontSize" sm={1} color="text.success"> Всички файлове ще бъдат изпратени! </Box>
                  </>
                )}
                {isDragReject && (
                  <div>
                    <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                      <CloseTwoToneIcon className="d-50" />
                    </div>
                    <Box fontSize="fontSize" sm={1} color="text.danger"> Някои файлове ще бъдат отказани! </Box>
                  </div>
                )}
                {!isDragActive && (
                  <>
                    <div className="d-100 btn-icon mb-3 hover-scale-lg bg-white shadow-light-sm rounded-circle text-primary">
                      <CloudUploadTwoToneIcon className="d-50" />
                    </div>
                    <Box fontSize="fontSize" sm={1}> Пуснете до {maxFiles} снимки тук{' '}
                      <span className="font-size-xs text-dark">
                        (jpg/png файлове)
                      </span>
                    </Box>
                  </>
                )}
                <small className="py-2 text-black-50">или</small>
                </Hidden>
                <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
                  <span className="px-2">Изберете до {maxFiles} снимки</span>
                </Button>
              </div>
            </div>
          </Box>
          <div>
            <div className="font-weight-bold my-4 text-uppercase text-dark font-size-sm text-center">
              Избрани снимки
            </div>
            {files.length <= 0 && (
              <Box textAlign="center" fontSize="fontSize" sm={1} color="text.info"> липсват </Box>
            )}
            {files.length > 0 && (
              <>
                <Alert severity="success" className="text-center mb-3">
                  Избрахте <b>{files.length}</b> снимки!
                </Alert>
                <List component="div" className="font-size-sm">
                  {files}
                </List>
              </>
            )}
          </div>

          <Box textAlign="center" mb={5}>
            <Button
              fullWidth
              onClick={handleSubmit}
              className='text-uppercase font-weight-bold font-size-sm mt-4 btn-primary'>
              Изпрати
            </Button>
          </Box>
        </>
        ) : (
        <>
          <Alert severity='info'>
            <AlertTitle>Благодарим Ви!</AlertTitle>
            Вашият сигнал беше изпратен успешно.
            Ще го разгледаме при първа възможност.
          </Alert>

          <Box textAlign="center" mb={5}>
            <Button
              fullWidth
              onClick={(e) => setDone(false)}
              className='text-uppercase font-weight-bold font-size-sm mt-4 btn-primary'>
              Нов сигнал
            </Button>
          </Box>
        </>
        )}

      </Box>
    </PageTemplate>
    )
}

export default ReportIssue


