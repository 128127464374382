import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Box, InputAdornment, TextField } from '@material-ui/core'
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone'

import HeroPage from './hero_page'
import api from '../api'
import config from '../config'


const ResetPassword = (props) => {
    const [email, setEmail] = useState('')
    const [done, setDone] = useState(false)
    const [error, setError] = useState('')
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!email) {
            return
        }
        api.reset_password( email)
            .then( (data) => setDone(true) )
            .catch( (data) => {
                if (data.email && data.email.length > 0) {
                    setError( data.email[0])
                }
            })
    }
    if (done) {
        const user_instructions = 'След малко ще получите инструкции за възстановяване на Вашата парола. Моля проверете своята електронна поща за нови съобщения както и в папка Spam.'
        return <Redirect to={{ pathname: config.urls.login, state: {user_instructions: user_instructions} }}/>
    }
    return (
    <HeroPage
        title="Възстановяване на парола"
        subtitle="Не помните паролата си? Няма проблем, с радост ще Ви помогнем да я възстановите!"
        button_text="Възстановяване на парола"
        onSubmit={handleSubmit}
        error={error}
        link_to={config.urls.root}
        link_text="Обратно към Вход"
        >
      <Box pt={1} mb={3}>
        <TextField
          fullWidth
          label="Имейл адрес"
          variant='outlined'
          type='email'
          name='email'
          onChange={ (e) => setEmail(e.target.value) }
          placeholder='Вашият имейл адрес'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'> <MailOutlineTwoToneIcon /> </InputAdornment>
            )
          }}
        />
      </Box>
    </HeroPage>
    )
}

export default ResetPassword


