import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { inject, observer } from 'mobx-react'
import {
    Box,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles( (theme) => ({
    table: {
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '40%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    column: {
        flexBasis: '50%',
    },
}))

const currency_formatter = new Intl.NumberFormat('bg-BG', {
    style: 'currency',
    currency: 'BGN',
})
const value = (field, row) => {
    const res = field.value( row)
    if (field.format === 'amount') {
        return currency_formatter.format(res)
    }
    return res
}


const Empty = props => (
    <Paper>
      <div className="card-header">
        <div className="card-header--title font-weight-bold">Няма данни</div>
      </div>
    </Paper>
)


const SmallBody = props => {
    const classes = useStyles()
    const { idx, row, config } = props
    return (
    <Table size="small" className={classes.table}>
      <TableBody>
      { config.map( (f, f_idx) => {
            return !f.heading && (
            <TableRow key={`row_${idx}__field_${f_idx}`}>
              <TableCell>{f.label}</TableCell>
              <TableCell align='right'>{value(f, row)}</TableCell>
            </TableRow>
            )
        }
      )}
      </TableBody>
    </Table>
    )
}


const Small = props => {
    const { config, rows } = props
    const classes = useStyles()
    const details = config.find( o => o.details)
    return (
    <>
    { rows.map( (row, idx) => (
      <Accordion key={`row_${idx}`}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          { config.map( (f, f_idx) => {
                return Boolean(f.heading) && (
                <Typography key={`row_${idx}__field_${f_idx}`} className={classes.heading}>
                  {value(f, row)}
                </Typography>
                )
            }
          )}
          { config.map( (f, f_idx) => {
                return Boolean(f.heading2) && (
                <Typography key={`row_${idx}__field_${f_idx}`} className={classes.secondaryHeading}>
                  {value(f, row)}
                </Typography>
                )
            }
          )}
        </AccordionSummary>

        <AccordionDetails>
        { details ?
          value(details, row)
          : (<Box margin={1}><SmallBody idx={idx} row={row} config={config} /> </Box>)
        }
        </AccordionDetails>
      </Accordion>
    ))}
    </>
    )
}

const DataTable = inject('app_store')( observer( props => {
    const classes = useStyles()
    const { config, rows } = props
    const small = useMediaQuery( useTheme().breakpoints.down('md'))

    if (props.app_store.loading) {
        return (
        <Box p={2}>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
        </Box>
        )
    }

    if (!rows.length) {
        return <Empty {...props} />
    }
    if (small && config.length > 2) {
        return ( <Small {...props} /> )
    }
    return (
    <TableContainer>
      <Table size="medium" className={classes.table}>
        <TableHead>
          <TableRow>
          { config.map( (f, idx) => (
            <TableCell key={`hdr_${idx}`} align={f.align}>{f.label}</TableCell>
            ))
          }
          </TableRow>
        </TableHead>
        <TableBody>
        { rows.map( (row, idx) => (
            <TableRow key={`row_${idx}`} >
            { config.map( (f, f_idx) => (
              <TableCell key={`row${idx}__col${f_idx}`} align={f.align}>{value(f, row)}</TableCell>
              ))
            }
            </TableRow>
          ))
        }
        </TableBody>
      </Table>
    </TableContainer>
    )
}))

export default DataTable


