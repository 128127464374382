import { observable, action, computed, makeObservable} from 'mobx'

import { posthog } from './config'
import config from './config'

const API_TOKEN_KEY = 'api_token'

    /*
    webpush_subscribe( sub) {
    webpush_unsubscribe() {
        return this.api.webpush_unsubscribe()
            .then( data => {
                this.set_user({...this.user, webpush_subscription: null})
                return data
            })
    }
    */


export class AppStore {
    loading = 0
    unseen_message_count = null
    unseen_notification_count = null

    push_subscription = null
    push_manager = null

    constructor( api) {
        this.api = api

        makeObservable( this, {
            loading: observable,
            push_subscription: observable,
            push_manager: observable,
            unseen_message_count: observable,
            unseen_notification_count: observable,
            increment_loading: action.bound,
            decrement_loading: action.bound,
            set_push_subscription: action.bound,
            set_push_manager: action.bound,
            set_unseen_message_count: action.bound,
            set_unseen_notification_count: action.bound,
        })
        api.add_middleware('request',
            'inc_loading',
            config => {
                this.increment_loading()
                return config
            }
        )
        api.add_middleware('response',
            'finish_loading',
            resp => {
                this.decrement_loading()
                return resp
            },
            error => {
                this.decrement_loading()
                return Promise.reject(error)
            }
        )
        api.set_success_callback('unread_messages_count',
            resp => this.set_unseen_message_count( resp.headers['x-unseen-announcements'])
        )
        api.set_success_callback('unread_notifications_count',
            resp => this.set_unseen_notification_count( resp.headers['x-unseen-notifications'])
        )
    }
    increment_loading() {
        this.loading += 1
    }
    decrement_loading() {
        if (this.loading > 0) {
            this.loading -= 1
        }
    }

    push_subscribe() {
        return this.push_manager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: config.VAPID_PUBLIC_KEY,
        }).then( sub => {
            this.set_push_subscription( sub)
            return sub
        })
    }
    push_unsubscribe() {
        return this.push_subscription.unsubscribe()
            .then( (success) => {
                this.set_push_subscription( null)
                return success
            })
    }
    set_push_manager( push_manager) {
        this.push_manager = push_manager
        if (push_manager) {
            push_manager.getSubscription().then( sub => {
                this.set_push_subscription( sub)
            })
        }
    }
    set_push_subscription( sub) {
        if (this.push_subscription && this.push_subscription !== sub) {
            this.api.webpush_unsubscribe( this.push_subscription)
        }
        this.push_subscription = sub
        if (sub) {
            this.api.webpush_subscribe( sub)
        }
    }
    set_unseen_message_count( count) {
        this.unseen_message_count = count
    }
    set_unseen_notification_count( count) {
        this.unseen_notification_count = count
    }
}


export class AuthStore {
    api_token = null
    user = null

    constructor( api) {
        this.api = api

        makeObservable( this, {
            api_token: observable,
            user: observable,
            user_address: computed,
            login: action.bound,
            logout: action.bound,
            set_user: action.bound,
            set_api_token: action.bound,
        })
        const token = localStorage.getItem( API_TOKEN_KEY)
        if (token) {
            this.try_token( token)
        }
    }
    set_user( user) {
        this.user = user
        if (posthog) {
            if (user) {
                posthog.identify( user.id)
                posthog.people.set({email: user.email})
            } else {
                posthog.reset()
            }
        }
    }
    try_token( token) {
        this._set_api_token( token)
        return this.api.get_current_user()
            .then( user => {
                this.set_user(user)
                this.set_api_token(token)
                return user
            })
            .catch( data => this.set_api_token(null) )
    }
    login( email, password) {
        return this.api.login( email, password)
            .then( data => this.try_token( data.key) )
    }
    logout() {
        return this.api.logout()
            .finally( () => { this.set_api_token(null) })
    }
    initial_setup( email, phone) {
        return this.api.initial_setup( email, phone)
            .then( data => {
                this.set_user(data)
                return data
            })
    }
    set_password( password) {
        return this.api.change_password( password)
            .then( data => {
                this.set_user({...this.user, has_usable_password: true})
                return data
            })
    }
    reset_password_confirm( password, uid, token) {
        return this.api.reset_password_confirm( password, uid, token)
            .then( data => {
                this.try_token( data.token)
                return data
            })
    }

    update_user( last_name, email, phone) {
        return this.api.update_user( last_name, email, phone)
            .then( data => {
                this.set_user(data)
                return data
            })
    }

    get user_address() {
        return this.user ? this.user.address : ''
    }

    get user_email() {
        return this.user ? this.user.email : ''
    }
    get user_phone_number() {
        return this.user ? this.user.phone_number : ''
    }

    get user_last_name() {
        return this.user ? this.user.last_name : ''
    }

    set_api_token( api_token) {
        if (api_token && this.api_token === api_token) {
            return
        }
        this.api_token = api_token
        localStorage.setItem( API_TOKEN_KEY, api_token)
        this._set_api_token( api_token)
    }
    _set_api_token( api_token) {
        if (!api_token) {
            localStorage.removeItem( API_TOKEN_KEY)
            this.api.clear_auth_key()
            this.api.set_unauthenticated_handler(null)
            this.set_user(null)
            return
        }
        this.api.set_auth_key( api_token)
        this.api.set_unauthenticated_handler( error => {
            this.api.cancel_pending_requests()
            this.set_api_token(null)
        })
    }
}


export class UIStore {
    constructor() {
        makeObservable( this, {
            sidebar: observable,
            toast: observable,
            header: observable,
            main: observable,
            footer: observable,
            page_title: observable,
            toggleSidebar: action.bound,
            toggleSidebarMobile: action.bound,
            setToast: action.bound,
        })
    }
    toast = {}
    sidebar = {
        fixed: true,
        footer: true,
        shadow: false,
        style: 'app-sidebar--dark',
        userbox: true,
        mobile: false,
        open: false,
    }

    header = {
        fixed: true,
        shadow: true,
        bgTransparent: true,
        searchHover: false,
        drawerToggle: false,
    }

    main = {
        contentBackground: '',
        themeConfiguratorToggle: false,
    }

    footer = {
        fixed: false,
        shadow: false,
        bgTransparent: true,
    }

    page_title = {
        style: '',
        background: '',
        shadow: false,
        iconBox: true,
        description: true
    }
    setToast(text, severity) {
        if (text) {
            this.toast = {text: text, severity: severity}
        } else {
            this.toast = {}
        }
    }

    toggleSidebarMobile() {
        this.sidebar.mobile = !this.sidebar.mobile
    }
    toggleSidebar() {
        this.sidebar.open = !this.sidebar.open
    }
}



