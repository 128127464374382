import React from 'react'
import { Grid, } from '@material-ui/core'
import illustration from '../assets/images/404.svg'

export default function Error404() {
  return (
    <>
      <div className='app-wrapper bg-white'>
        <div className='app-main'>
          <div className='app-content p-0'>
            <div className='app-inner-content-layout--main'>
              <div className='flex-grow-1 w-100 d-flex align-items-center'>
                <div className='bg-composed-wrapper--content'>
                  <div className='hero-wrapper bg-composed-wrapper min-vh-100'>
                    <div className='flex-grow-1 w-100 d-flex align-items-center'>
                      <Grid
                        item
                        lg={6}
                        md={9}
                        className='px-4 px-lg-0 mx-auto text-center text-black'>
                        <img
                          src={illustration}
                          className='w-50 mx-auto d-block my-5 img-fluid'
                          alt='...'
                        />

                        <h3 className='font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50'>
                          Страницата, която търсите не съществува.
                        </h3>
                        <p>
                          Съжаляваме, вероятно информацията е преместена на друга страница.
                        </p>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

