import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'

import Alert from '@material-ui/lab/Alert'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    InputAdornment,
    TextField,
    Button
} from '@material-ui/core'
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone'
import MuiPhoneNumber from 'material-ui-phone-number'


const EditProfile = inject('auth_store', 'ui_store')( observer( (props) => {
    const auth_store = props.auth_store
    //const [first_name, setFirstName] = useState( auth_store.user_first_name)
    const [last_name, setLastName] = useState( auth_store.user_last_name)
    const [email, setEmail] = useState( auth_store.user_email)
    const [phone, setPhone] = useState( auth_store.user_phone_number)
    const [error, setError] = useState('')
    const handleSubmit = e => {
        e.preventDefault()
        if (!email) {
            setError('Моля, въведете имейл за връзка с Вас!')
            return
        }
        auth_store.update_user( last_name, email, phone)
            .then( (data) => {
                props.ui_store.setToast('Профилът Ви беше променен успешно.')
                props.onClose()
            })
            .catch( (data) => {
                if (data)
                    setError( data.email || data.last_name || data.phone_number)
            })
    }
    return (
    <Dialog
      classes={{ paper: 'modal-content rounded-lg' }}
      open={true}
      onClose={() => props.onClose()}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Промяна на профил</DialogTitle>
      <DialogContent className="p-4">
        <DialogContentText>
          Моля, въведете фамилия и имейл адрес.
        </DialogContentText>

        { error && (<Alert severity='error'>{error}</Alert>) }

        {/*
        <div className='mb-3'>
          <label className='font-weight-bold mb-2'>
              Собствено име
          </label>
          <TextField
            variant='outlined'
            size='small'
            fullWidth
            name='first_name' onChange={ e => setFirstName(e.target.value) }
          />
        </div>
        */}
        <div className='mb-3'>
          <label className='font-weight-bold mb-2'>
              Фамилия
          </label>
          <TextField
            variant='outlined'
            size='small'
            fullWidth
            value={last_name}
            name='last_name'
            onChange={ e => setLastName(e.target.value) }
          />
        </div>
        <div className='mb-3'>
          <label className='font-weight-bold mb-2'>
            Имейл
          </label>
          <TextField
            fullWidth
            variant='outlined'
            type='email'
            value={email}
            disabled={true}
            name='email'
            onChange={ (e) => setEmail(e.target.value) }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <MailOutlineTwoToneIcon />
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className='mb-3'>
          <label className='font-weight-bold mb-2'>
            Телефон
          </label>
          <MuiPhoneNumber defaultCountry='bg'
            value={phone}
            onChange={setPhone}
            fullWidth
            variant='outlined'
          />

        </div>
      </DialogContent>
      <DialogActions className="p-4">
        <Button
          onClick={ () => props.onClose() }
          variant="text"
          className="mr-3">
          Отказ
        </Button>
        <Button onClick={handleSubmit} className="btn-primary">
          Запис
        </Button>
      </DialogActions>
    </Dialog>
    )
}))

export default EditProfile



