import React from 'react'
import { Events } from './messages'

const Notifications = (props) => {
    return (
    <Events
        type='notifications'
        title='Известия'
        description='Хронология на получените известия'
        api_mark_read='read_notification'
    />
    )
}

export default Notifications

