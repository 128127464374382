import { Paper, Hidden } from '@material-ui/core'
import PageTitle from './page_title'


const PageTemplate = (props) => {
    const {title, description, barebone} = props
    if (barebone) {
        return (
        <>
          <Hidden smDown>
            <PageTitle titleHeading={title} titleDescription={description} />
          </Hidden>
          {props.children}
        </>
        )
    }
    return (
    <>
      <Hidden smDown>
        <PageTitle titleHeading={title} titleDescription={description} />
      </Hidden>
      <Paper>
        <Hidden mdUp>
          <div className="card-header">
            <div className="card-header--title font-weight-bold"> {title} </div>
          </div>
        </Hidden>
        {props.children}
      </Paper>
    </>
    )
}


export default PageTemplate


