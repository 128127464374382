import { Integrations } from "@sentry/tracing"
import posthog_orig from 'posthog-js'
import moment from 'moment-timezone'
import 'moment/locale/bg'

moment.locale('bg')
moment.tz.setDefault('Europe/Sofia')


const env = {
    get(name, defaultValue) {
        return process.env[`REACT_APP_${name}`] || defaultValue
    },
    str(name, defaultValue) {
        return this.get(name, defaultValue)
    },
    bool(name, defaultValue) {
        return this.parse(name, defaultValue)
    },
    parse(name, defaultValue) {
        const val = this.get(name)
        return val ? JSON.parse(val) : defaultValue
    },
}

const DEBUG = env.bool('DEBUG', true)
const API_ENDPOINT = env.get('API_ENDPOINT', 'http://localhost:8000')
const VERSION = env.get('VERSION', '')
const API_ADD_TRAILING_SLASH = true
const USE_AUTH=1

const VAPID_PUBLIC_KEY = env.get('VAPID_PUBLIC_KEY', '')

const POSTHOG = {
    api_host: '', //'https://posthog.resolve.bg',
    api_key: '', //'UeESzhQURbddIt_5nGp9KEO-k5BHNW8BKEuE7OmQgu0',
}
export var posthog = null
if (POSTHOG.api_host && POSTHOG.api_key) {
    posthog = posthog_orig
    posthog.init( POSTHOG.api_key, { api_host: POSTHOG.api_host })
}

const SENTRY = {
    dsn: "https://761ccc718e444381bedd05bf3f21c530@sentry.resolve.bg/8",
    release: VERSION,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
}

const urls = {
    root: '/',
    login: '/вход',
    logout: '/изход',
    home: '/меню',
    initial_setup: '/първоначална-настройка',
    set_password: '/нова-парола',
    password_reset: '/забравена-парола',
    password_reset_confirm: '/reset-password/:uid/:token',
    poll_vote_token: '/poll-vote/:token',
    poll_vote: '/гласуване',
    report_issue: '/подай-сигнал',
    polls: '/анкети',
    obligation: '/чакащи-задължения',
    communal_report: '/справка-комунални',
    communal_report_all: '/справка-комунални-общи',
    payments_report: '/справка-плащания',
    revisions: '/отчети',
    messages: '/съобщения',
    notifications: '/известия',
    documents: '/документи',
    offices: '/офиси',
    offices_anon: '/списък-офиси',
}
const all = { urls, DEBUG, API_ENDPOINT,
    API_ADD_TRAILING_SLASH, USE_AUTH, VERSION, posthog, SENTRY,
    VAPID_PUBLIC_KEY,
}
export default all
