import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'

import { Button, Tooltip} from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PerfectScrollbar from 'react-perfect-scrollbar'
import logo from '../assets/images/logo.png'
import config from '../config'
import { MenuItems } from './navigation_menu'


const Sidebar = inject('ui_store')( observer( (props) => {
    const ui_store = props.ui_store
    const sidebar = ui_store.sidebar
    return (
    <>
      <div
        className={clsx('app-sidebar', sidebar.style, {
          'app-sidebar--shadow': sidebar.shadow
        })}>
        <SidebarHeader />

        <div className='app-sidebar--content'>
          <SidebarMenu />
        </div>
        {sidebar.footer && <SidebarFooter />}
      </div>
      <div
        onClick={ui_store.toggleSidebarMobile}
        className={clsx('app-sidebar-overlay', {
          'is-active': sidebar.mobile
        })}
      />
    </>
    )
}))


export const MenuItem = (props) => {
    const { label, url} = props
    return (
    <NavLink to={url}>
      <span className='sidebar-icon'> {props.children} </span>
      <span className='sidebar-item-label'>{label}</span>
    </NavLink>
    )
}


const SidebarMenu = props => {
    return (
    <PerfectScrollbar>
      <div className='sidebar-navigation'>
        <ul>
          <li>
            <MenuItems component={MenuItem} />
          </li>
        </ul>
      </div>
    </PerfectScrollbar>
    )
}


const SidebarHeader = inject('ui_store')( observer( (props) => {
    const ui_store = props.ui_store
    return (
    <>
      <div className='app-sidebar--header'>
        <div className='app-sidebar-logo'>
          <NavLink to={config.urls.root}
            title=''
            className='app-sidebar-logo'>
            <div className='app-sidebar-logo--icon'>
              <img
                alt='Кантора Акорд'
                src={logo}
              />
            </div>
            <div className='app-sidebar-logo--text'>
              <b>Кантора Акорд</b>
              <span>Професионален домоуправител</span>
            </div>
          </NavLink>
        </div>
        <Tooltip title='Collapse sidebar' placement='right' arrow>
          <Button
            onClick={ui_store.toggleSidebar}
            className='btn btn-sm collapse-sidebar-btn'>
            <FontAwesomeIcon icon={['far', 'dot-circle']} size='lg' />
          </Button>
        </Tooltip>
        <Button
          className={clsx(
            'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
            { 'is-active': ui_store.sidebar.mobile}
          )}
          onClick={ui_store.toggleSidebarMobile}>
          <span className='hamburger-box'>
            <span className='hamburger-inner' />
          </span>
        </Button>
        <Tooltip title='Expand sidebar' placement='right' arrow>
          <Button
            onClick={ui_store.toggleSidebar}
            className='expand-sidebar-btn btn btn-sm'>
            <FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
          </Button>
        </Tooltip>
      </div>
    </>
    )
}))


const SidebarFooter = () => {
  return (
    <> {/*
      <div className='app-sidebar--footer'>
        <ul>
          <li>
            <Tooltip arrow placement='top' title='Analytics Dashboard'>
              <Button
                variant='text'
                className='btn-transition-none d-40 mx-2 p-0'>
                <ListAltTwoToneIcon />
              </Button>
            </Tooltip>
          </li>
          <li>
            <Tooltip arrow placement='top' title='Crypto Components'>
              <Button
                variant='text'
                className='btn-transition-none d-40 mx-2 p-0'>
                <MarkunreadTwoToneIcon />
              </Button>
            </Tooltip>
          </li>
          <li>
            <Tooltip arrow placement='top' title='Buttons'>
              <Button
                variant='text'
                className='btn-transition-none d-40 mx-2 p-0'>
                <LinkedCameraTwoToneIcon />
              </Button>
            </Tooltip>
          </li>
        </ul>
      </div>
      */}
    </>
  )
}


export default Sidebar


