import React from 'react'
import clsx from 'clsx'
import { Badge, Box, Hidden, Button, IconButton } from '@material-ui/core'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import NotificationsIcon from '@material-ui/icons/Notifications'
import MailIcon from '@material-ui/icons/Mail'

import { inject, observer } from 'mobx-react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import logo from '../assets/images/logo.png'

import config from '../config'
import Userbox from './userbox'


const Header = inject('ui_store', 'app_store')( observer( props => {
    const ui_store = props.ui_store
    const location = useLocation()
    const show_back_button = !location.pathname.includes( config.urls.home)

    const app_store = props.app_store
    const unseen_messages = app_store.unseen_message_count === "0" ? "" : (app_store.unseen_message_count || "")
    const unseen_notifications = app_store.unseen_notification_count === "0" ? "" : (app_store.unseen_notification_count || "")

    return (
    <Box
      style={{ width: '100%' }}
      className={clsx('app-header', {
        'app-header--shadow': ui_store.header.shadow,
        'app-header--opacity-bg': ui_store.header.bgTransparent
      })}>
      <div className='app-header--pane'>
        <Hidden mdDown>
          <Button
            className={clsx(
              'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
              { 'is-active': ui_store.sidebar.mobile }
            )}
            onClick={ui_store.toggleSidebarMobile}>
            <span className='hamburger-box'>
              <span className='hamburger-inner' />
            </span>
          </Button>
        </Hidden>
        { show_back_button && (
        <Hidden lgUp>
          <IconButton color="primary" component={RouterLink} to={config.urls.root}> <ArrowBackIcon /> </IconButton>
        </Hidden>
        )}
        { !show_back_button && (
        <Hidden lgUp>
          <div className='app-sidebar-logo--icon'>
            <img
              alt='Кантора Акорд'
              src={logo}
            />
          </div>
        </Hidden>
        )}
      </div>

      <div className='app-header--pane'>
        <IconButton color="primary" component={RouterLink} to={config.urls.messages}>
          <Badge badgeContent={unseen_messages} > <MailIcon /> </Badge>
        </IconButton>
        <IconButton color="primary" component={RouterLink} to={config.urls.notifications}>
          <Badge badgeContent={unseen_notifications}> <NotificationsIcon /> </Badge>
        </IconButton>
        <Userbox />
      </div>
    </Box>
    )
}))

export default Header


