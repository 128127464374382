import React, { useEffect, useState, } from 'react'
import moment from 'moment-timezone'

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core'

import PageTemplate from './page_template'
import DataTable from './datatable'
import api from '../api'


const Revisions = (props) => {
    const [rows, setRows] = useState([])
    const [openRevision, setOpenRevision] = useState(null)
    useEffect(() => {
        api.revisions()
            .then( data => setRows(data))
            .catch( error => !api.cancelled && setRows([]))
    }, [])
    var config = []
    if (rows.length) {
        config = [
            { align: 'left', label: 'Месец', heading: true, value: row => moment(row.month).format('MMM YYYY') },
            { align: 'right', label: 'Начална каса', value: row => row.start_balance, format: 'amount' },
            { align: 'right', label: 'Приход', value: row => row.total_income, format: 'amount' },
            { align: 'right', label: 'Разход', value: row => row.total_expenditures, format: 'amount' },
            { align: 'right', label: 'Крайна каса', value: row => row.end_balance, format: 'amount', heading2: true },
            { align: 'center', label: '', value: row => (
                <Button variant='outlined' color='primary' onClick={() => setOpenRevision(row)}>
                    Детайли
                </Button>
            )},
        ]
    }
    return (
    <PageTemplate
        title='Отчети'
        description='Месечни отчети, приети от oбщо събрание'>
      <DataTable config={config} rows={rows} />
      {
          openRevision
          && <RevisionDetails revision={openRevision} onClose={() => setOpenRevision(null)} />
      }
    </PageTemplate>
    )
}


const RevisionDetails = ({ revision, onClose, ...props }) => {
    console.log(revision)
    const table_config = [
        { align: 'left', label: '', heading: true, value: row => row[0]},
        { align: 'right', label: '', heading2: true, value: row => row[1], format: 'amount'},
    ]
    const rows = [
        ['Начална каса', revision.start_balance],
        ...revision.expenditures.communal.map((o, idx) => [o.label, o.value]),
        ['Разход ремонти', revision.expenditures.non_communal],
        ['Разход общо', revision.total_expenditures],
        ['Приход такси', revision.income.communal],
        ['Приход ремонти', revision.income.non_communal],
        ['Приход общо', revision.total_income],
        ['Крайна каса', revision.end_balance],
    ]

    return (
    <Dialog
      fullScreen
      classes={{ paper: 'modal-content rounded-lg' }}
      open={true}
      onClose={() => onClose()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Отчет за {moment(revision.month).format('MMMM YYYY')}
      </DialogTitle>

      <DialogContent className="p-4">
        <DataTable config={table_config} rows={rows} />
      </DialogContent>

      <DialogActions className="p-4">
        <Button onClick={ () => onClose() } className="btn-primary">
          Затвори
        </Button>
      </DialogActions>
    </Dialog>
    )
}


export default Revisions
