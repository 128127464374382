import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'

import { Box,Typography, Paper} from '@material-ui/core'

import PageTemplate from './page_template'
import DataTable from './datatable'
import api from '../api'


const Obligation = (props) => {
    const [data, setData] = useState({})
    const rows = data.rows || []
    const roundtrip_dates = data.roundtrip_dates || []
    useEffect(() => {
        api.obligation()
            .then( data => setData(data))
            .catch( error => !api.cancelled && setData({}))
    }, [])
    if (!rows.length) {
        return (
        <PageTemplate
            title='Моите задължения'
            description='Разходи за домакинството, очакващи плащане'>
            <Paper>
              <div className="card-header">
                <div className="card-header--title font-weight-bold">Нямате чакащи задължения</div>
              </div>
            </Paper>
        </PageTemplate>
        )
    }
    const config = [
        { align: 'right', label: 'Сума', heading: true, value: row => row.amount, format: 'amount' },
        { align: 'left', label: 'Месец', heading2: true, value: row => moment(row.created).format('MMMM YYYY') },
        { align: 'left', label: 'Описание', details: true, value: row => (<Typography> {row.description} </Typography>) },
    ]

    const roundtrips_config = [
        { align: 'left', label: 'Дата', value: row => moment(row).format('DD.MM.YYYY HH:mm') },
    ]
    return (
    <>
      <PageTemplate
          title='Моите задължения'
          description='Разходи за домакинството, очакващи плащане'
        >
        <DataTable config={config} rows={rows} />
      </PageTemplate>

      <Box py={1}>
        <Paper>
          <div className="card-header">
            <div className="card-header--title font-weight-bold">Предстоящи посещения от касиер</div>
          </div>
          <DataTable config={roundtrips_config} rows={roundtrip_dates} />
        </Paper>
      </Box>
    </>
    )
}


export default Obligation


