import React, { useState } from 'react'
import Alert from '@material-ui/lab/Alert'
import { inject, observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import {
    Box, Dialog, DialogTitle, DialogContent,
    DialogContentText, DialogActions,
    TextField, Button } from '@material-ui/core'

import HeroPage from './hero_page'
import config from '../config'


const PasswordChangeDialog = props => {
    return (
    <Dialog
      classes={{ paper: 'modal-content rounded-lg' }}
      open={true}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Промяна на парола</DialogTitle>
      <DialogContent className="p-4">
        <DialogContentText>
          Моля, въведете вашата нова парола
        </DialogContentText>

        {props.children}
      </DialogContent>
      <DialogActions className="p-4">
        <Button
          onClick={ () => props.onClose() }
          variant="text"
          className="mr-3">
          Отказ
        </Button>
        <Button onClick={props.onSubmit} className="btn-primary">
          Запис
        </Button>
      </DialogActions>
    </Dialog>
    )
}

const PasswordChangePage = inject('auth_store')( observer( props => {
    if (props.auth_store.user.has_usable_password) {
        return <Redirect to={config.urls.root} />
    }
    return (
    <HeroPage
        title='Нова парола'
        subtitle='Необходимо е да смените служебната си парола с нова, известна само на Вас.'
        onSubmit={props.onSubmit}
        error={props.error}
        button_text="Запис"
      >
      <Box pt={1} mb={4}>
        {props.children}
      </Box>
    </HeroPage>
    )
}))


const PasswordChange = inject('ui_store', 'auth_store')( props => {
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [error, setError] = useState('')
    const handleSubmit = e => {
        e.preventDefault()
        if (password1 !== password2) {
            setError('Паролите не съвпадат')
            return
        }
        props.auth_store.set_password( password1)
            .then( (data) => {
                props.ui_store.setToast('Паролата Ви беше променена успешно.')
                if (props.onClose) {
                    props.onClose()
                }
            })
            .catch( (data) => {
                if (data && data.new_password) {
                    setError('Невалидна парола')
                }
            })
    }
    const Wrapper = props.as_page ? PasswordChangePage : PasswordChangeDialog

    return (
    <Wrapper onClose={props.onClose} onSubmit={handleSubmit}>
      { error && ( <Alert severity='error'> {error} </Alert>) }

      <div className='mb-3'>
        <label className='font-weight-bold mb-2'>
            Нова парола
        </label>
        <TextField
          variant='outlined'
          size='small'
          fullWidth
          type='password'
          name='password1' onChange={ e => setPassword1(e.target.value) }
        />
      </div>
      <div className='mb-3'>
        <label className='font-weight-bold mb-2'>
            Повторете новата парола
        </label>
        <TextField
          variant='outlined'
          size='small'
          fullWidth
          type='password'
          name='password2' onChange={ e => setPassword2(e.target.value) }
        />
      </div>
    </Wrapper>
    )
})

export default PasswordChange



