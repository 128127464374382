import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'

import { Box, InputAdornment, TextField } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone'
import MuiPhoneNumber from 'material-ui-phone-number'

import HeroPage from './hero_page'
import config from '../config'


const SetEmail = inject('auth_store')( observer( props => {
    const auth_store = props.auth_store
    const [email, setEmail] = useState( auth_store.user_email)
    const [phone, setPhone] = useState( auth_store.user_phone_number)
    const [error, setError] = useState('')
    const [done, setDone] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault()
        if (email) {
            auth_store.initial_setup( email, phone)
                .then( (data) => {
                    setError('')
                    setDone(true)
                })
                .catch( (data) => setError( data.email || "Моля, въведете валиден телефонен номер"))
        }
    }
    if (auth_store.user_email && auth_store.user.has_usable_password) {
        return <Redirect to={config.urls.root} />
    }

    return (
    <HeroPage
        title='Нека поддържаме връзка'
        subtitle='Стараем се да Ви информираме за важните за Вас събития, без да досаждаме излишно.'
        onSubmit={handleSubmit}
        button_text={ done ? "" : "Изпрати връзка за потвърждение"}
        error={error}
        link_to={config.urls.logout}
        link_text="Вход като друг потребител"
      >
      { done ? (
        <Alert severity='info'>
          <AlertTitle>Код за потвърждение</AlertTitle>
            Изпратихме съобщение на Вашата електронна поща с връзка за потвърждение.
            Моля, проверете за нови съобщения и отворете посочената връзка.
            Възможно е съобщението ни да се озове в папка Spam.
        </Alert>
      ) : (
      <>
      <Box pt={1} mb={4}>
        <TextField
          fullWidth
          variant='outlined'
          type='email'
          name='email'
          value={email}
          onChange={ e => setEmail(e.target.value) }
          placeholder='Вашият имейл адрес'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'> <MailOutlineTwoToneIcon /> </InputAdornment>
            )
          }}
        />
      </Box>
      <Box pt={1} mb={4}>
        <MuiPhoneNumber defaultCountry='bg'
          value={phone}
          onChange={setPhone}
          fullWidth
          variant='outlined'
        />
      </Box>
      </>
      )}
    </HeroPage>
    )
}))

export default SetEmail


