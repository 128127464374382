import axios from 'axios'
import config from './config'


//const cancel_token_source = axios.CancelToken.source()
const http_client = axios.create({
    baseURL: config.API_ENDPOINT,
    addTrailingSlash: config.API_ADD_TRAILING_SLASH,
    //cancelToken: cancel_token_source.token,
})

http_client.interceptors.request.use( (req_config) => {
    if (req_config.addTrailingSlash && req_config.url[req_config.url.length-1] !== '/') {
        req_config.url += '/'
    }
    if (config.DEBUG) {
        console.debug('REQUEST', req_config.method, req_config.url, req_config.data)
    }
    return req_config
})

http_client.interceptors.response.use(
    resp => {
        if (config.DEBUG) console.debug('RESPONSE', resp.data)
        return resp
    },
    error => {
        if (error.response) {
            if (config.DEBUG) console.debug('RESPONSE', error.response)
            return Promise.reject(error.response)
        }
        if (config.DEBUG) console.debug('RESPONSE ERROR', error)
        return Promise.reject(error)
    }
)


class Api {
    constructor( http_client) {
        this.cancelled = false
        this.http_client = http_client
        this.send_request = this.send_request.bind(this)
        this.middleware = {}
    }
    login( username, password) {
        return this.send_request('post', 'login', { username: username, password: password})
    }
    logout() {
        return this.send_request('post', 'logout')
    }
    get_current_user() {
        return this.send_request('get', 'user')
    }
    update_user( last_name, email, phone) {
        return this.send_request('put', 'user', { last_name: last_name, email: email, phone_number: phone})
    }
    change_password( new_password) {
        return this.send_request('post', 'password/change', { new_password: new_password })
    }
    reset_password( email) {
        return this.send_request('post', 'password/reset', { email: email})
    }
    initial_setup( email, phone) {
        return this.send_request('patch', 'user', { email: email, phone_number: phone})
    }
    reset_password_confirm( new_password, uid, token) {
        return this.send_request('post', 'password/reset/confirm', {
            new_password: new_password,
            uid: uid,
            token: token
        })
    }
    obligation() {
        return this.send_request('get', 'obligation')
    }
    messages() {
        return this.send_request('get', 'announcements')
    }
    read_message( msg_id) {
        return this.send_request('get', `announcements/${msg_id}`)
    }
    notifications() {
        return this.send_request('get', 'notifications')
    }
    read_notification( n_id) {
        return this.send_request('get', `notifications/${n_id}`)
    }

    polls() {
        return this.send_request('get', 'polls')
    }
    poll_token( token) {
        return this.send_request('post', `polls-token`, {token: token})
    }
    vote( poll_id, answer_number) {
        return this.send_request('post', `polls/${poll_id}/vote`, { answer_number: answer_number})
    }
    vote_with_token( token, answer_number) {
        return this.send_request('post', `polls-token/vote`, { token: token, answer_number: answer_number})
    }
    vote_results( poll_id) {
        return this.send_request('get', `polls/${poll_id}/results`)
    }

    communal_report() {
        return this.send_request('get', 'communal-report')
    }
    communal_report_all() {
        return this.send_request('get', 'communal-report-all')
    }
    payments_report() {
        return this.send_request('get', 'payments-report')
    }
    revisions() {
        return this.send_request('get', 'revisions')
    }
    documents() {
        return this.send_request('get', 'documents')
    }
    download_document( doc_id) {
        return this.send_request('get', `documents/${doc_id}/download`, null, { responseType: 'blob' })
    }

    report_issue( description, images) {
        let data = new FormData()
        images.forEach( (img, idx) => {
            data.append(`image${idx+1}`, img, img.name)
        })
        data.append('description', description)
        return this.send_request('post', 'issue-report', data,
            { headers: {'content-type': 'multipart/form-data'}}
        )
    }

    webpush_subscribe( subscription) {
        let sub_data = subscription.toJSON().keys
        let data = {
            endpoint: subscription.endpoint,
            p256dh: sub_data.p256dh,
            auth: sub_data.auth,
            browser: navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/ig)[0].toLowerCase(),
        }
        return this.send_request('post', 'webpush-subscription', data)
    }
    webpush_unsubscribe( subscription) {
        // TODO add support for multiple subscriptions
        return this.send_request('delete', 'webpush-subscription')
    }

    send_request( method, urlpath, data, extra_config) {
        this.cancelled = false
        const config = {
            withCredentials: true, //cross-site? XXX cors.. Access-Control-Allow-Origin: the-srvr
            method: method,
            //cancelToken: cancel_token_source.token,
            ...(extra_config || {})
        }
        if (data) {
            config[ (method === 'get' ? 'params' : 'data')] = data
        }
        return this.http_client.request( urlpath, config)
            .then( (resp) => resp.data )
            .catch( (response) => {
                if (response.status === 400) {
                    return Promise.reject( response.data) // let others deal with validation error data
                }
                return Promise.reject(response)
            })
    }
    cancel_pending_requests() {
        this.cancelled = true
        //cancel_token_source.cancel()
    }
    set_auth_key( key) {
        this.http_client.defaults.headers.common['Authorization'] = `Token ${key}`
    }
    clear_auth_key( key) {
        delete this.http_client.defaults.headers.common['Authorization']
    }

    set_server_error_handler( callback) {
        this.set_error_callback('server_error', error => (error.status === 500), callback)
    }
    set_unauthenticated_handler( callback) {
        this.set_error_callback('unauthenticated_error', error => (error.status === 401), callback)
    }
    set_network_error_handler( callback) {
        this.set_error_callback('network_error', error => (error.message === 'Network Error'), callback)
    }

    set_success_callback( name, callback) {
        if (!callback) {
            this.remove_middleware( name)
            return
        }
        const handler = (resp) => {
            callback( resp)
            return resp
        }
        this.add_middleware( 'response', name, handler)
    }

    set_error_callback( name, condition_func, callback) {
        if (!callback) {
            this.remove_middleware( name)
            return
        }
        const handler = (error) => {
            if (condition_func( error)) callback( error)
            return Promise.reject(error)
        }
        this.add_middleware( 'response', name, resp => resp, handler)
    }

    add_middleware( type, name, success_handler, error_handler) {
        const interceptors = this.http_client.interceptors[type]
        this.middleware[ name] = { type: type, func: interceptors.use( success_handler, error_handler) }
    }
    remove_middleware( name) {
        const handler = this.middleware[ name]
        if (handler) {
            const interceptors = this.http_client.interceptors[handler.type]
            delete this.middleware[ name]
            interceptors.eject( handler.func)
        }
    }

}

export default new Api( http_client)
