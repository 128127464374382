import React from 'react'

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import ReceiptIcon from '@material-ui/icons/Receipt'
//import HouseIcon from '@material-ui/icons/House'
import PersonIcon from '@material-ui/icons/Person'
import PeopleIcon from '@material-ui/icons/People'
import PaymentIcon from '@material-ui/icons/Payment'
import AssessmentIcon from '@material-ui/icons/Assessment'
//import ApartmentIcon from '@material-ui/icons/Apartment'
import BusinessIcon from '@material-ui/icons/Business'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import { Box, Grid, Card, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import config from '../config'


const MenuItem = (props) => {
    const history = useHistory()
    const {label, url} = props

    return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
      <Box p={2}>
        <Button
          onClick={(e) => history.push( url)}
          variant='outlined'
          className='d-block btn-outline-primary border-0 w-100 shadow-none py-3 btn-transition-none btn-animated-icon-sm'>
          <div className='mx-auto'>
            <span className='btn-wrapper--icon btn-icon mx-auto font-size-lg d-40 bg-light rounded-pill d-block text-primary mb-2'>
              {props.children}
            </span>
          </div>
          <span className='font-weight-bold font-size-md'>
            {label}
          </span>
        </Button>
      </Box>
    </Grid>
    )
}


export const MenuItems = (props) => {
    const ItemComponent = props.component
    return (
      <>
      <ItemComponent url={config.urls.obligation} label='Моите задължения'>
        <ReceiptIcon />
      </ItemComponent>
      <ItemComponent url={config.urls.communal_report} label='Моите разходи'>
        <PersonIcon/>
      </ItemComponent>
      <ItemComponent url={config.urls.payments_report} label='Моите плащания'>
        <PaymentIcon />
      </ItemComponent>
      <ItemComponent url={config.urls.polls} label='Анкети'>
        <AssignmentTurnedInIcon />
      </ItemComponent>
      <ItemComponent url={config.urls.communal_report_all} label='Приходи и разходи на ЕС'>
        <PeopleIcon />
      </ItemComponent>
      <ItemComponent url={config.urls.revisions} label='Отчети'>
        <AssessmentIcon/>
      </ItemComponent>
      <ItemComponent url={config.urls.report_issue} label='Сигнализирай за проблем'>
        <ReportProblemIcon />
      </ItemComponent>
      <ItemComponent url={config.urls.documents} label='Документи'>
        <AttachFileIcon />
      </ItemComponent>
      <ItemComponent url={config.urls.offices} label='Офиси'>
        <BusinessIcon />
      </ItemComponent>
      </>
    )
}

const NavigationMenu = (props) => {
    return (
    <Box display='flex' alignItems='flex-end' height='100%' justifyContent='center'>
    <Card>
      <div className='grid-menu grid-menu-2col'>
        <Grid container spacing={0}>
          <MenuItems component={MenuItem} />
        </Grid>
      </div>
    </Card>
    </Box>
    )
}

export default NavigationMenu
