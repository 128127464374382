import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import { Box, InputAdornment, TextField } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import PersonIcon from '@material-ui/icons/Person'
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone'

import HeroPage from './hero_page'
import { get_attrib } from '../misc'
import config from '../config'



const Login = inject('auth_store')( observer( (props) => {
    const user_instructions  = get_attrib( props, 'location.state.user_instructions')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const auth_store = props.auth_store
    const handleSubmit = e => {
        auth_store.login( username, password)
            .catch( (data) => setError('Неуспешен опит за вход с предоставените КИН и парола'))
        e.preventDefault()
    }
    if (auth_store.api_token) {
        return <Redirect to={config.urls.root} />
    }
    return (
    <HeroPage
        title="Вход"
        subtitle="Моля, въведете Вашия КИН и парола за да влезете в приложението"
        button_text="Вход"
        onSubmit={handleSubmit}
        error={error}
        link_to={config.urls.password_reset}
        link_text="Забравена парола?"
      >
      { user_instructions && ( <Alert severity='info'> {user_instructions} </Alert>) }
      <Box pt={1} mb={4}>
        <TextField
          fullWidth
          variant="outlined"
          name="username"
          label="КИН"
          onChange={ e => setUsername(e.target.value) }
          placeholder='Вашият клиентски номер'
          InputProps={{
            startAdornment: (
              <InputAdornment position="start"> <PersonIcon /> </InputAdornment>
            )
          }}
        />
      </Box>
      <Box mb={3}>
        <TextField
          fullWidth
          variant="outlined"
          name="password"
          label="Парола"
          type="password"
          onChange={ e => setPassword(e.target.value) }
          placeholder='Въведете Вашата парола'
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockTwoToneIcon />
              </InputAdornment>
            )
          }}
        />
      </Box>
    </HeroPage>
    )
}))

export default Login


