import React, { useEffect, useState } from 'react'

import Chart from 'react-apexcharts'
import {
    Typography,
    Button,
    Box,
    Grid,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

    CircularProgress,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
} from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import Alert from '@material-ui/lab/Alert'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'

import PageTemplate from './page_template'
import HeroPage from './hero_page'
import DataTable from './datatable'
import api from '../api'
import config from '../config'


const Polls = (props) => {
    const [rows, setRows] = useState([])
    const history = useHistory()
    useEffect(() => {
        api.polls()
            .then( data => setRows(data))
            .catch( error => !api.cancelled && setRows([]))
    }, [])
    var table_config = []

    if (rows.length) {
        table_config = [
            { align: 'left', label: 'Въпрос', value: row => row.question },
            { align: 'left', label: 'Гласуване до', heading: true, value: row => moment(row.end_date).format('DD.MM.YYYY') },
            { align: 'right', label: 'Гласували', heading2: true, value: row => `${row.votes_count}/${row.voters_count}` },
            { align: 'center', label: '', value: row => (
                <Button className='btn-primary'
                    onClick={(e) => history.push({
                        pathname: config.urls.poll_vote,
                        state: { poll: row }
                    })}
                >
                    Гласувай
                </Button>
            )},
        ]
    }
    return (
    <PageTemplate
        title='Анкети'
        description='Всички проведени анкети до момента'>
      <DataTable config={table_config} rows={rows} />
    </PageTemplate>
    )
}


export const PollVote = (props) => {
    const history = useHistory()
    const poll = props.location.state.poll
    const [resultsOpen, setResultsOpen] = useState(false)
    const [error, setError] = useState('')
    const [value, setValue] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        const num = parseInt(value, 10)
        if (!value) {
            setError('Моля, изберете един от посочените отговори!')
            return
        }
        setError('')
        api.vote( poll.id, num)
            .then( () => history.push( config.urls.root))
            .catch( (error) => setError(error))
    }
    return (
    <PageTemplate
        title='Гласуване'
        description={ poll.is_active ?
            'Моля, изберете един от посочените отговори и гласувайте!'
            : 'Гласуването по тази анкета е приключило.' }
    >

      { error && (
      <Box p={4}>
        <Alert severity='error'>{error}</Alert>
      </Box>
      )}

      <VoteForm value={value} onChange={setValue} poll={poll} />

      <Box p={4}>
        <Grid container>
          { poll.is_active && (
          <Grid item xs={6} md={4} lg={3} xl={3}>
              <Button variant='contained' onClick={handleSubmit} color="primary">Гласувай</Button>
          </Grid>
          )}
          <Grid item xs={6} md={4} lg={3} xl={3}>
              <Button variant='outlined' color='primary' onClick={() => setResultsOpen(true)}>
                  Резултати
              </Button>
          </Grid>
        </Grid>
      </Box>
      { resultsOpen && <PollResults poll={poll} onClose={() => setResultsOpen(false)} /> }
    </PageTemplate>
    )
}


const VoteForm = ({ value, onChange, poll, ...props }) => {
    return (
    <>
      <Box p={4}>
          <Typography variant="h5" >{poll.question} </Typography>
      </Box>
      <Box p={4}>
          <FormLabel component="legend">Отговори</FormLabel>
          <RadioGroup aria-label="answer" name="answer"
                value={value}
                onChange={ (event) => (onChange && onChange(event.target.value)) }
          >
            <Answer value='1' text={poll.answer1} attachment={poll.attachment1} />
            <Answer value='2' text={poll.answer2} attachment={poll.attachment2} />
            <Answer value='3' text={poll.answer3} attachment={poll.attachment3} />
            <Answer value='4' text={poll.answer4} attachment={poll.attachment4} />
            <Answer value='5' text={poll.answer5} attachment={poll.attachment5} />
          </RadioGroup>
      </Box>
    </>
    )
}


const Answer = ({value, text, attachment}) => {
    if (!text) {
        return null
    }
    return (
    <>
    <Box display="flex">
        <Grid item xs={7}>
            <FormControlLabel value={value} control={<Radio />} label={text} />
        </Grid>
        { attachment && (
        <Grid item xs={5}>
          <Button color="primary" href={attachment} startIcon={<CloudDownloadIcon/>}>
            Изтегли
          </Button>
        </Grid>
        )}
    </Box>
    </>
    )
}


const PollResults = ({ poll, onClose, ...props }) => {
    const series = [poll.voters_count - poll.votes_count]
    const labels = ['Негласувал']
    const [rows, setRows] = useState([])

    useEffect(() => {
        api.vote_results( poll.id)
            .then( data => setRows(data))
            .catch( error => !api.cancelled && setRows([]))
    }, [poll.id])

    for (let i = 1; i < 6; i++) {
        let s = poll[`answer${i}`]
        if (!s) {
            break
        }
        labels.push(`Отговор ${i}`)
        series.push(poll[`votes_count${i}`])
    }
    const options = {
        chart: { type: 'donut' },
        labels: labels,
    }
    var table_config = []
    if (rows.length) {
        table_config = [
            { align: 'left', label: 'Апартамент', heading: true, value: row => row.prop_id_code},
            { align: 'right', label: 'Отговор', heading2: true, value: row => row.answer_number || 'Негласувал' },
        ]
    }
    return (
    <Dialog
      fullScreen
      classes={{ paper: 'modal-content rounded-lg' }}
      open={true}
      onClose={() => onClose()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Резултати от гласуване</DialogTitle>
      <DialogContent className="p-4">
        <Box  display='flex' justifyContent="center">
          <Grid xs={12} sm={12} md={8} lg={6} xl={4}>
            <Chart options={options} series={series} type='donut'/>
          </Grid>
        </Box>
        <DataTable config={table_config} rows={rows} />
      </DialogContent>
      <DialogActions className="p-4">
        <Button onClick={ () => onClose() } className="btn-primary">
          Затвори
        </Button>
      </DialogActions>
    </Dialog>
    )
}


export const PollVoteWithToken = (props) => {
    const history = useHistory()
    const { token } = props.match.params
    const [loading, setLoading] = useState(true)
    const [poll, setPoll] = useState(null)
    const [error, setError] = useState('')
    const [value, setValue] = useState('')
    const [done, setDone] = useState(false)

    useEffect(() => {
        setLoading(true)
        api.poll_token( token)
            .then( data => setPoll(data))
            .catch( error => setError(error))
            .finally( () => setLoading(false))
    }, [token])

    if (loading) {
        return (
        <Box sx={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={100} variant='indeterminate' disableShrink={true} />
        </Box>
        )
    }

    const subtitle = (poll.is_active ?
        'Моля, изберете един от посочените отговори и гласувайте!'
        : 'Гласуването по тази анкета е приключило.'
    )
    const subtitle_done = 'Вашият глас беше успешно отразен.'
    const handleSubmit = (e) => {
        e.preventDefault()
        if (done) {
            history.push( config.urls.root)
        }
        const num = parseInt(value, 10)
        if (!value) {
            setError('Моля, изберете един от посочените отговори!')
            return
        }
        setError('')
        api.vote_with_token( token, num)
            .then( () => setDone(true))
            .catch( (error) => setError(error))
    }
    const btnText = (!done && poll.is_active) ? "Гласувай" : "Към Акорд Онлайн"
    return (
    <HeroPage
        title="Гласуване"
        subtitle={done ? subtitle_done : subtitle}
        button_text={btnText}
        onSubmit={handleSubmit}
        error={error}
        >
        {done && (<Container>Благодарим Ви, че гласувахте!</Container>)}
        {!done && (<VoteForm value={value} onChange={setValue} poll={poll} />)}
    </HeroPage>
    )
}

export default Polls


