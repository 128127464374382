import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Hidden, Typography, Menu, Button, List, ListItem, Divider } from '@material-ui/core'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import { inject, observer } from 'mobx-react'

import PasswordChange from './password_change'
import EditProfile from './edit_profile'
import AppInfo from './app_info'


const Item = (props) => {
    const { label, onOpen } = props
    const ItemComponent = props.component
    const [open, setOpen] = useState(false)
    const show = () => {
        setOpen(true)
        if (onOpen) onOpen()
    }
    return (
    <>
      <ListItem button className='d-block text-left' onClick={show} >
        {label}
      </ListItem>
      { open && ( <ItemComponent onClose={() => setOpen(false)} /> )}
    </>
    )
}

const checkNotificationPromise = () => {
    if (!window.Notification) {
        return false
    }
    try {
        Notification.requestPermission().then()
    } catch(e) {
        return false
    }
    return true
}

const Userbox = inject('auth_store', 'app_store', 'ui_store')( observer( (props) => {
    const {auth_store, app_store, ui_store} = props
    const user = auth_store.user
    // FIXME user should not be null
    const [anchorEl, setAnchorEl] = useState(null)
    const closeMenu = () => setAnchorEl(null)

    const Notification = window.Notification
    const notificationsSupport = Boolean( Notification && app_store.push_manager)
    const subscriptionEnabled = Boolean(
        Notification
        && app_store.push_subscription
        && (Notification.permission === 'granted')
    )

    const onPermission = (perm) => {
        //console.log('Notification permission status:', perm)
        if (perm === 'granted') {
            app_store.push_subscribe().then( () => {
                ui_store.setToast('Абонаментът за известия е включен.')
            })
        } else {
            ui_store.setToast(
                'Необходимо е да разрешите показване на известия от Акорд Онлайн в настройките на браузъра.',
                'error'
            )
        }
    }
    const toggleSubscription = () => {
        if (subscriptionEnabled) {
            app_store.push_unsubscribe()
                .then( () => {
                    ui_store.setToast('Абонаментът за известия е изключен.')
                })
        } else {
            // safari still using the callback syntax
            if (checkNotificationPromise()) {
                Notification.requestPermission().then( onPermission)
            } else {
                Notification.requestPermission( onPermission)
            }
        }
    }
    return (
    <>
      <Button
        variant='text'
        onClick={ (event) => setAnchorEl(event.currentTarget) }
        className='ml-2 btn-transition-none text-left ml-2 p-0 bg-transparent d-flex align-items-center'
        disableRipple>

        <Hidden smDown>
          <Box pl={2}>
            <Box fontWeight="fontWeightBold" pt={2} height={1}>{auth_store.user_address}</Box>
            <span className='text-black-50'>{user ? 'КИН:' + user.username : ''}</span>
          </Box>
        </Hidden>
        <Hidden mdUp> <AccountBoxIcon color="primary"/> </Hidden>
        <span className='pl-1 pl-xl-3'>
          <FontAwesomeIcon icon={['fas', 'angle-down']} className='opacity-5' />
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(anchorEl)}
        classes={{ list: 'p-0' }}
        onClose={ () => setAnchorEl(null) }>
        <Box p={0} overflow="hidden">
          <div className='d-flex px-3 pt-3 align-items-center justify-content-between'>
            <Typography className='text-capitalize pl-1 font-weight-bold text-primary'>
              <span>{auth_store.user_address}</span>
            </Typography>
          </div>
          <List component='div'
            className='nav-neutral-primary text-left d-block px-3 pb-3'>

            <Item label="Промяна на парола" component={PasswordChange} onOpen={closeMenu} />
            <Item label="Профил" component={EditProfile} onOpen={closeMenu} />

            { notificationsSupport && (
                <ListItem button onClick={toggleSubscription}>
                  { subscriptionEnabled ? "Изключи абонамента за известия" : "Включи абонамент за известия"}
                </ListItem>
            )}

            <Divider className="w-100"/>

            <Item label="За Акорд Онлайн" component={AppInfo} onOpen={closeMenu}/>
            <ListItem button className='d-block text-left' onClick={props.auth_store.logout} >
              Изход
            </ListItem>
          </List>
        </Box>
      </Menu>
    </>
  )
}))

export default Userbox
