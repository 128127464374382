import React, { useState } from 'react'
import { inject } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import { Box, TextField } from '@material-ui/core'

import HeroPage from './hero_page'

import config from '../config'


const ResetPasswordConfirm = inject('auth_store')( (props) => {
    const { uid, token } = props.match.params
    const { auth_store } = props

    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [done, setDone] = useState(false)
    const [error, setError] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!password1) {
            setError('Моля, въведете парола')
            return
        }
        if (password1 !== password2) {
            setError('Паролите не съвпадат')
            return
        }
        auth_store.reset_password_confirm( password1, uid, token)
            .then( (data) => setDone(true) )
            .catch( (data) => {
                if (data.token || data.uid) {
                    setError('Тази връзка е била използвана или е вече невалидна.')
                } else if (data.new_password) {
                    setError('Невалидна парола')
                }
            })
    }
    if (done) {
        //const user_instructions = 'Успешно променихте своята парола. Моля използвайте я за да влезете.'
        //return <Redirect to={{ pathname: config.urls.login, state: {user_instructions: user_instructions} }}/>
        return <Redirect to={config.urls.root} />
    }
    return (
    <HeroPage
        title="Възстановяване на достъпа"
        subtitle="Моля, въведете Вашата нова парола"
        button_text="Готово"
        onSubmit={handleSubmit}
        error={error}
        link_to={config.urls.root}
        link_text="Обратно към Вход"
        >
      <Box pt={1} mb={1}>
        <label className='font-weight-bold mb-2'>
          Парола
        </label>
        <TextField
          fullWidth
          variant='outlined'
          placeholder='Въведете нова парола'
          type='password'
          name='password1' onChange={ (e) => setPassword1(e.target.value) }
        />
      </Box>
      <Box mb={3}>
        <label className='font-weight-bold mb-2'>
          Парола (повторно)
        </label>
        <TextField
          fullWidth
          variant='outlined'
          placeholder='Повторете новата парола'
          type='password'
          name='password2' onChange={ (e) => setPassword2(e.target.value) }
        />
      </Box>
    </HeroPage>
    )
})

export default ResetPasswordConfirm


