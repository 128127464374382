import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'

import Sidebar from './sidebar'
import Header from './header'
import Footer from './footer'


const MainLayout = inject('ui_store', 'auth_store')( observer( (props) => {
    const ui_store = props.ui_store
    const header = ui_store.header
    const sidebar = ui_store.sidebar

    return (
    <div className={clsx('app-wrapper', ui_store.main.contentBackground, {
        'header-drawer-open': header.drawerToggle,
        'app-sidebar-collapsed': sidebar.open,
        'app-sidebar-mobile-open': sidebar.mobile,
        'app-sidebar-fixed': sidebar.fixed,
        'app-header-fixed': header.fixed,
        'app-footer-fixed': ui_store.footer.fixed,
        'search-wrapper-open': header.searchHover
      })}>

      <Sidebar />

      <div className='app-main'>
        <Header/>
        <div className='app-content'>
          <div className='app-content--inner'>
            <div className='app-content--inner__wrapper'>{props.children}</div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
    )
}))


export default MainLayout


