import React from 'react'
import { Grid, Button, Card, CardContent } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import PhoneSharpIcon from '@material-ui/icons/PhoneSharp'
import MailSharpIcon from '@material-ui/icons/MailSharp'
import LanguageIcon from '@material-ui/icons/Language'
//import NavigationIcon from '@material-ui/icons/Navigation'
//import NavigationTwoToneIcon from '@material-ui/icons/NavigationTwoTone'
import ChatBubbleOutlineSharpIcon from '@material-ui/icons/ChatBubbleOutlineSharp'

import HeroPage from './hero_page'
import PageTemplate from './page_template'

const useStyles = makeStyles( theme => ({
    button: {
        margin: theme.spacing(1),
    },
}))

const MainPhone = props => {
    const classes = useStyles()
    return (
    <Card className="card-box bg-composed-wrapper bg-vicious-stance p-3 p-xl-4 text-white">
      <div className="bg-composed-img-2 bg-composed-wrapper--image" />
      <div className="bg-composed-wrapper--content d-block text-center text-xl-left d-xl-flex justify-content-between align-items-center">
        <p className="opacity-9 font=size-xl mr-0 mr-xl-3 mb-4 mb-xl-0">
          Централен телефон за връзка
        </p>
        <Button
          href="tel:070020776"
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<PhoneSharpIcon />}
        >
          0700 20 776
        </Button>
        <Button
          href="tel:052500200"
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<PhoneSharpIcon />}
        >
          052 500 200
        </Button>

      </div>
    </Card>
    )
}

const Addresses = props => {
    const style = { color: '#B22222' }
    return (
    <Card>
      <div className='card-header'>
        <div className='card-header--title font-size-md font-weight-bold py-2'>
          Офиси в град Варна
        </div>
      </div>
      <CardContent>
        <p><strong>бул. “Република” 117</strong>,&nbsp; ТЦ “Явор” ет. 2</p>
        <p><strong>бул. “Васил Левски”</strong>, Компютьрен център – РИСК Електроник, ет. 2</p>
        <br/>
        <p><strong><span style={style}>Работно време:</span> </strong>понеделник -&nbsp;петък</p>
        <p>от 09:00 до 16:30 часа <u>за плащане на каса</u></p>
        <p>от 09:00 до 17:00 часа <u>за въпроси и консултации</u></p>
      </CardContent>
    </Card>
    )
}

const Contacts = props => {
    const style = { color: '#B22222' }
    return (
    <Card>
      <div className='card-header'>
        <div className='card-header--title font-size-md font-weight-bold py-2'>
          Контакти
        </div>
      </div>
      <CardContent>
        <p><strong><ChatBubbleOutlineSharpIcon color="primary"/> &nbsp; viber: </strong>0883 220 028 - телефон само за съобщения</p>
        <p><strong>
          <a href="mailto:office@akord.bg?" style={style}><MailSharpIcon  color="primary" /> &nbsp; office@akord.bg</a>
        </strong></p>
        <p><strong>
          <a href="https://kantoraakord.com/" style={style}><LanguageIcon color="primary" /> &nbsp; https://kantoraakord.com </a>
        </strong></p>

        <p><strong>Телефони:&nbsp; </strong></p>
        <p><a href="tel:+359988937335" style={style}><PhoneSharpIcon color="primary" /> &nbsp; 0988 93 73 35 </a>офис ТЦ "Явор"</p>
        <p><a href="tel:+359988937070" style={style}><PhoneSharpIcon color="primary" /> &nbsp; 0988 93 70 70 </a>офис бул. "Левски"</p>
        <p><a href="tel:+359884528008" style={style}><PhoneSharpIcon color="primary" /> &nbsp; 0884 52 80 08 </a>Калоян Попов</p>
        <p><a href="tel:+359887715414" style={style}><PhoneSharpIcon color="primary" /> &nbsp; 0887 71 54 14 </a>Николай Йорданов</p>
        <p><a href="tel:+359883220025" style={style}><PhoneSharpIcon color="primary" /> &nbsp; 0883 22 00 25 </a>Лазар Николов</p>
        <p><a href="tel:+359888211869" style={style}><PhoneSharpIcon color="primary" /> &nbsp; 0888 21 18 69 </a>Юлий Манойлов</p>
        <p><a href="tel:+359889772424" style={style}><PhoneSharpIcon color="primary" /> &nbsp; 0889 77 24 24 </a>Емил Пенчев</p>
      </CardContent>
    </Card>
    )
}

const OfficesContent = (props) => {
    return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12}>
        <MainPhone />
      </Grid>
      <Grid item lg={12} md={12}>
        <Addresses />
      </Grid>
      <Grid item lg={12} md={12}>
        <Contacts />
      </Grid>
    </Grid>
    )
}

const title = 'Офиси'
const description = 'Адресите, на които можете да ни намерите. Очакваме Ви!'

const Offices = (props) => {
    return (
    <PageTemplate title={title} description={description} barebone={true} >
      <OfficesContent />
    </PageTemplate>
    )
}

const OfficesAnon = (props) => {
    return (
    <HeroPage
        title={title} subtitle={description}
        >
        <OfficesContent />
    </HeroPage>
    )
}

const all = { Auth: Offices, Anon: OfficesAnon }
export default all


