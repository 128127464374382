import React from 'react'
import { inject, observer } from 'mobx-react'
import Alert from '@material-ui/lab/Alert'
import { Slide, Snackbar } from '@material-ui/core'


const Toast = inject('ui_store')(observer( props => {
    const transition = props => (<Slide {...props} direction="up" />)
    const toast = props.ui_store.toast

    const handleToastClose = () => {
        props.ui_store.setToast(null)
    }

    return (
    <Snackbar open={Boolean(props.ui_store.toast.text)}
        autoHideDuration={3000}
        TransitionComponent={transition}
        onClose={handleToastClose}
        anchorOrigin={{ vertical:'bottom', horizontal: 'center'}}
     >
      <Alert onClose={handleToastClose} severity={toast.severity || 'success'}>
      {props.ui_store.toast.text}
      </Alert>
    </Snackbar>
    )
}))

export default Toast


