import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'

import { makeStyles } from '@material-ui/core/styles'
import { Hidden, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import PageTemplate from './page_template'
import { posthog } from '../config'
import api from '../api'


const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}))
export const truncate = (input, size) => ( input.length > size ? `${input.substring(0, size)}...` : input)
export const Events = (props) => {
    const { title, description, type, api_mark_read} = props

    const classes = useStyles()
    const [rows, setRows] = useState([])
    const [expanded, setExpanded] = useState(false)
    const handleChange = (row) => (event, isExpanded) => {
        setExpanded( isExpanded ? row.id : false)
        if (posthog) {
            posthog.capture( type, { [`expand-${type}`]: isExpanded })
        }
        if (!row.seen) {
            api[ api_mark_read]( row.id).then(
                () => setRows(
                    rows.map( (r, idx) => (
                        r !== row ? r : {...r, seen: true}
                    ))
                )
            )
        }
    }
    useEffect(() => {
        api[ type]()
            .then( data => setRows(data))
            .catch( error => !api.cancelled && setRows([]))
    }, [type])

    const styles = {
        unread: {
            fontWeight: "bold",
        },
    }
    styles.unread_summary = { ...styles.unread}
    return (
    <PageTemplate
        title={title}
        description={description}>

        {rows.map( (row) => {
            return (
          <Accordion
                key={`row_${row.id}`}
                expanded={expanded === row.id}
                onChange={handleChange( row)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}
                style={ row.seen ? {} : styles.unread }>
                {moment(row.created).format('DD.MM.YYYY HH:mm')}
              </Typography>
              <Typography className={classes.secondaryHeading}
                style={ row.seen ? {} : styles.unread_summary}>
                { truncate(row.text, 15)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Hidden lgUp>
                <Typography> {row.text} </Typography>
              </Hidden>
              <Hidden mdDown>
              <pre><Typography> {row.text} </Typography></pre>
              </Hidden>
            </AccordionDetails>
          </Accordion>
        )})}
    </PageTemplate>
    )
}


const Messages = (props) => {
    return (
    <Events
        type='messages'
        title='Съобщения'
        description='Хронология на получените съобщения'
        api_mark_read='read_message'
    />
    )
}

export default Messages
