//import cc from 'create-react-class'
//import { createElement } from 'react'

//export const h = createElement
//export const print = console.log
//export const ERROR = console.error


export function get_attrib(obj, propString) {
    if (!propString)
        return obj

    var prop, props = propString.split('.')

    for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
        prop = props[i]

        var candidate = obj[prop]
        if (candidate !== undefined) {
            obj = candidate
        } else {
            break
        }
    }
    return obj[props[i]]
}



